@mixin HeadingStyle {
  color: var(--primary);
  font-size: 20px;
  margin-bottom: 14px;
  text-transform: uppercase;
}

@mixin fullWidth {
  width: 100%;
}

@mixin afterIcon {
  font-family: Flaticon;
  line-height: 12px;
  position: absolute;
  right: 0;
  top: 0;
  width: 13px;
}

@mixin titleOverflowHide {
  -webkit-align-self: center;
  align-self: center;
  -webkit-box-flex: 1 1 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  padding-right: 12px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-calc(100% - 56px);
  width: calc(100% - 56px);
}

@mixin grayBorder {
  border: 1px solid var(--border-color);
}

@mixin antInputFocus {
  border: 1px solid var(--chinese-silver);
  box-shadow: none;
}

@mixin dFlex($alignment: top, $justify: space-between) {
  align-items: $alignment;
  display: flex !important;
  justify-content: $justify;
}

@mixin boxShadow($boxShadow: 0 4px 34px 0 rgba(0, 0, 0, 0.16)) {
  box-shadow: $boxShadow;
}

@mixin backgroundNoRepeat($repeat: no-repeat, $size: cover, $position: bottom center) {
  background-position: $position;
  background-repeat: $repeat;
  background-size: $size;
}

@mixin beforeContentLeft($content: "", $position: absolute, $top: 0, $left: 0, $right: unset, $bottom: unset, $width: 100%, $height: 100%, $background: #222, $opacity: 0.4) {
  background: $background;
  bottom: $bottom;
  content: $content;
  height: $height;
  left: $left;
  opacity: $opacity;
  position: $position;
  right: $right;
  top: $top;
  width: $width;
}

@mixin cardDesign {
  background-image: linear-gradient(180deg, #f5f5f5eb 0%, #f5f5f5c2 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 4px;
  box-shadow: 0 2px 40px 0 rgba(135, 135, 135, 0.25%);
}

@mixin btnGradient {
  background: rgb(229, 0, 16);
  background: linear-gradient(273deg, rgba(229, 0, 16, 1) 0%, rgba(34, 34, 34, 0.9416141456582633) 82%);
}

@mixin bgGradient {
  background: rgb(34, 34, 34);
  background: linear-gradient(0deg, rgba(34, 34, 34, 0.7315301120448179) 0%, rgba(34, 34, 34, 0) 95%);
}

@mixin circleBorder($padding: 3px, $background: linear-gradient(90deg, #e50010, #fe19a1)) {
  background: $background;
  border-radius: 100%;
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: $padding;
  pointer-events: none;
  position: absolute;
}

@mixin timeCard {
  background: var(--primary);
  border-radius: 5px;
  color: var(--white);
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  text-transform: uppercase;
}

@mixin overfloweClips($width: 112px) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

@mixin card {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

@mixin cardHover {
  box-shadow: 0 1px 12px 2px #dbdbdb;
}

@mixin mainCard($padding: 30px, $background: #fff) {
  --box-shadow-color: var(--application-border-color);

  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .16);
  background: $background;
  border: 0;
  box-shadow: 0 3px 12px var(--box-shadow-color);
  padding: $padding;
}

@mixin ellipseLine($line: 3) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin gridDesign($size: 33.333%, $gap: unset, $column: repeat(auto-fit, minmax($size, 1fr))) {
  display: -ms-grid;
  display: grid;
  grid-column-gap: $gap;
  -ms-grid-columns: (minmax($size, 1fr)) [auto-fit];
  grid-template-columns: $column;
}

@mixin secondCard($padding: 24px 36px, $radius: 4px, $background: var(--white)) {
  background: $background;
  border: 1px solid #d6d6d6;
  border-radius: $radius;
  padding: $padding;
}

@mixin elementPosition($position: absolute, $top: 0, $bottom: unset, $left: 0, $right: unset, ) {
  bottom: $bottom;
  left: $left;
  position: $position;
  right: $right;
  top: $top;
}

@mixin BorderRadius($radius: 4px) {
  border-radius: $radius;
}

@mixin circleButton($width: 49px, $height: 61px, $fontSize: 18px, $lineHeight: 2.6) {
  background: var(--secondary);
  border-radius: 0 40px 40px 0;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: $fontSize;
  height: $height;
  line-height: $lineHeight;
  margin: 0;
  text-align: center;
  width: $width;
}

@mixin dispayBox {
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
}

@mixin counterNumber($fontSize: 105px, $webkitFill: transparent, $bgcolor: linear-gradient(to bottom, rgba(0, 0, 0, 0.14) 0%, rgba(255, 255, 255, 0) 100%), $color: unset, ) {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: $bgcolor;
  bottom: 0;
  color: $color;
  content: "0" counter(my-sec-counter);
  counter-increment: my-sec-counter;
  font-size: $fontSize;
  font-weight: 900;
  letter-spacing: -4px;
  line-height: 1;
  position: absolute;
  right: 26px;
  -webkit-text-fill-color: $webkitFill;
  z-index: 1;

  @media (max-width: 800px) {
    font-size: 50px;
  }
}

@mixin autoGridLayout($size: 20%) {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax($size, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}
