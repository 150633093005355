// gilroy font family starts

@font-face {
  font-family: 'GilroyRegular';
  font-style: normal;
  font-weight: 400;
  src: url('./gilroy/Gilroy-Regular.woff2') format('woff2'), url('./gilroy/Gilroy-Regular.woff') format('woff'), url('./gilroy/Gilroy-Regular.eot') format('eot');
}

@font-face {
  font-family: 'GilroyMedium';
  font-style: normal;
  font-weight: 500;
  src: url('./gilroy/Gilroy-Medium.woff2') format('woff2'), url('./gilroy/Gilroy-Medium.woff') format('woff'), url('./gilroy/Gilroy-Medium.eot') format('eot');
}

@font-face {
  font-family: 'GilroyBold';
  font-style: normal;
  font-weight: 600;
  src: url('./gilroy/Gilroy-Bold.woff2') format('woff2'), url('./gilroy/Gilroy-Bold.woff') format('woff'), url('./gilroy/Gilroy-Bold.eot') format('eot');
}

// gilroy font family starts

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('./roboto/Roboto-Thin.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('./roboto/Roboto-ThinItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./roboto/Roboto-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('./roboto/Roboto-LightItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./roboto/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./roboto/Roboto-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./roboto/Roboto-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('./roboto/Roboto-MediumItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./roboto/Roboto-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('./roboto/Roboto-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./roboto/Roboto-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('./roboto/Roboto-BlackItalic.ttf') format('ttf');
}

// HM Sans font family

// Regular font
@font-face {
  font-family: 'HMSans';
  font-weight: 400;
  src: url('./HMSans/HMSans-Regular.woff2') format('woff2'), url('./HMSans/HMSans-Regular.woff') format('woff'), url('./HMSans/HMSans-Regular.eot') format('eot');
}

// light
@font-face {
  font-family: 'HMSans';
  font-weight: 300;
  src: url('./HMSans/HMSans-Light.woff2') format('woff2'), url('./HMSans/HMSans-Light.woff') format('woff'), url('./HMSans/HMSans-Light.eot') format('eot');
}

@font-face {
  font-family: 'HMSans';
  font-weight: 300;
  font-style: italic;
  src: url('./HMSans/HMSans-LightItalic.woff2') format('woff2'), url('./HMSans/HMSans-LightItalic.woff') format('woff'), url('./HMSans/HMSans-LightItalic.eot') format('eot');
}

// Semi Bold
@font-face {
  font-family: 'HMSans';
  font-weight: 600;
  src: url('./HMSans/HMSans-SemiBold.woff2') format('woff2'), url('./HMSans/HMSans-SemiBold.woff') format('woff'), url('./HMSans/HMSans-SemiBold.eot') format('eot');
}

@font-face {
  font-family: 'HMSans';
  font-weight: 600;
  font-style: italic;
  src: url('./HMSans/HMSans-SemiBoldItalic.woff2') format('woff2'), url('./HMSans/HMSans-SemiBoldItalic.woff') format('woff'), url('./HMSans/HMSans-SemiBoldItalic.eot') format('eot');
}

// Bold
@font-face {
  font-family: 'HMSans';
  font-weight: 700;
  src: url('./HMSans/HMSans-Bold.woff2') format('woff2'), url('./HMSans/HMSans-Bold.woff') format('woff'), url('./HMSans/HMSans-Bold.eot') format('eot');
}

@font-face {
  font-family: 'HMSans';
  font-weight: 700;
  font-style: italic;
  src: url('./HMSans/HMSans-BoldItalic.woff2') format('woff2'), url('./HMSans/HMSans-BoldItalic.woff') format('woff'), url('./HMSans/HMSans-BoldItalic.eot') format('eot');
}